#privacyPolicy{
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 999;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;  
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    padding: 20px;
}

#privacyPolicy .bold{
    font-weight: bold;
}

#privacyPolicy.show{
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.2s ease-in-out;
}

#privacyPolicy::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hero-overlay-dark);
}

#privacyPolicy > .container{
    width: 100%;
    height: 100%;
    max-width: 966px;
    max-height: 670px;
    padding: 20px;
    background-color: white;
    z-index: 1000;
    border-radius: 15px;
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.25);
}

#privacyPolicy > .container > .header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
}

#privacyPolicy > .container > .header > .title{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#privacyPolicy > .container > .header > .title > .label{
    font-weight: bold;
    font-size: 18px;
    color: var(--primary);
}

#privacyPolicy > .container > .header > .title > .date{
    font-weight: 300;
    font-size: 14px;
}

#privacyPolicy > .container > .header > .action > svg{
    width: 30px;
    height: 30px;
    cursor: pointer;
}

#privacyPolicy > .container > .content{
    width: 100%;
    height: 100%;
    max-height: 500px;
    overflow-y: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#privacyPolicy > .container > .content > .paragraph,
#privacyPolicy > .container > .content > .title-desc > .desc,
#privacyPolicy > .container > .content > ul > li{
    font-size: 14px;
    font-weight: 300;
}

#privacyPolicy > .container > .content > .title-desc{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#privacyPolicy > .container > .content > .title-desc > .title{
    font-size: 18px;
    font-weight: bold;
    color: var(--primary);
}

#privacyPolicy > .container > .content > ul{
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#privacyPolicy > .container > .content > ul > li::marker{
    color: var(--secondary);
}

#privacyPolicy > .container > .action{
    width: 100%;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

#privacyPolicy > .container > .action > button{
    width: 158px;
    height: 42px;
    border-radius: 4px;
    border: none;
    background-color: var(--primary);
    color: white;
    font-size: 14px;
    font-weight: 600;
    transition: all 250ms ease-in-out;
    cursor: pointer;
}

#privacyPolicy > .container > .action > button:hover{
    opacity: 0.8;
}