#circleMemberCarousel{
    width: 100%;
    height: 100%;
    
}

#circleMemberCarousel > .wrapper{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    position: relative;
    background: rgb(26,68,67);
    background: linear-gradient(0deg, rgba(26,68,67,1) 0%, rgba(104,163,161,1) 100%);
}

#circleMemberCarousel > .wrapper > .carousel-content{
    width: 100%;
    height: 100%;
    max-width: 1536px;
    padding: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
}

#circleMemberCarousel > .wrapper > .carousel-content > .title > p{
    color: white;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
}

#circleMemberCarousel > .wrapper > .carousel-content .card{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

#circleMemberCarousel > .wrapper > .carousel-content .card img{
    width: 100%;
    max-width: 250px;
    min-width: 250px;
    height: 100%;
    max-height: 250px;
    min-height: 250px;
    border-radius: 50%;
}

#circleMemberCarousel > .wrapper > .carousel-content .card > .name{
    color: white;
    font-weight: 500;
    font-size: 18px;
}

#circleMemberCarousel .swiper-container {
    position: relative;
    width: 100%;
    max-width: 1200px;
    padding-top: 50px;
    padding-bottom: 50px;
}
  
#circleMemberCarousel .swiper-slide {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 0;
}

.swiper-button-prev::after, .swiper-button-next::after{
    color: white !important;
}

@media only screen and (max-width: 1023px){
    #circleMemberCarousel > .wrapper > .carousel-content{
        padding: 50px;
    }
}

@media only screen and (max-width: 425px){
    #circleMemberCarousel > .wrapper > .carousel-content .card img{
        width: 100%;
        max-width: 200px;
        min-width: 200px;
        height: 100%;
        max-height: 200px;
        min-height: 200px;
        border-radius: 50%;
    }

    #circleMemberCarousel > .wrapper > .carousel-content{
        padding: 20px;
    }
}