#portfolioContent{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#portfolioContent > .wrapper{
    width: 100%;
    height: 100%;
    max-width: 1536px;
    padding: 100px;
}

#portfolioContent > .wrapper > .posts{
    width: 100%;
    height: 1000px;
    padding-bottom: 200px;
}

#portfolioContent > .wrapper > .posts > .title{
    width: 100%;
    text-align: center;
    padding: 30px 20px;
    font-size: 32px;
    color: var(--primary);
    font-weight: 300;
}

#portfolioContent > .wrapper > .achievements{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

#portfolioContent > .wrapper > .achievements > .title{
    font-size: 36px;
    font-weight: 300;
    text-align: center;
}

#portfolioContent > .wrapper > .achievements > .desc{
    font-weight: 300;
    width: 100%;
    max-width: 810px;
    text-align: center;
}

#portfolioContent > .wrapper > .achievements .skills-grid {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}
  
#portfolioContent > .wrapper > .achievements .skill {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}

#portfolioContent > .wrapper > .achievements .skill > .progress-group{
    display: flex;
    gap: 5px;
    align-items: center;
}
  
#portfolioContent > .wrapper > .achievements .skill-name {
    flex: 1;
    text-align: left;
    font-weight: bold;
    color: var(--primary);
}
  
#portfolioContent > .wrapper > .achievements .progress-bar {
    width: 100%;
    background: var(--secondary);
    overflow: hidden;
    height: 5px;
}
  
#portfolioContent > .wrapper > .achievements .progress {
    height: 100%;
    background: var(--primary);
}
  
#portfolioContent > .wrapper > .achievements .percentage {
    flex: 0;
    font-weight: bold;
}

@media only screen and (max-width: 1118px){
    #portfolioContent > .wrapper > .achievements > .achievement-values{
        gap: 100px;
    }
}

@media only screen and (max-width: 1024px){
    #portfolioContent > .wrapper{
        padding: 50px;
    }
}

@media only screen and (max-width: 768px){
    #portfolioContent > .wrapper > .achievements > .title{
        font-size: 32px;
    }

    #portfolioContent > .wrapper > .achievements > .achievement-values > .achievement > .amount{
        font-size: 54px;
    }

    #portfolioContent > .wrapper > .achievements > .achievement-values > .achievement > .label{
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px){
    #portfolioContent > .wrapper{
        padding: 20px;
    }
}

@media only screen and (max-width: 425px){
    #portfolioContent > .wrapper > .achievements .skills-grid {
        grid-template-columns: 1fr;
    }
}