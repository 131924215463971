#faq{
    width: 100%;
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

#faq > .title{
    font-size: 28px;
    font-weight: bold;
    color: var(--primary);
}

#faq > .faq-flex{
    width: 100%;
    height: 100%;
    display: flex;
    gap: 20px;
}

#faq > .faq-flex > .faq-group{
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#faq > .faq-flex > .faq-group > .grid-item{
    width: 100%;
    min-height: 90px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px 40px;
    border-radius: 16px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: all 250ms ease-in-out;
    user-select: none;
}

#faq > .faq-flex > .faq-group > .grid-item.active{
    height: 100%;
}

#faq > .faq-flex > .faq-group > .grid-item > .title{
    display: flex;
    align-items: center;
}

#faq > .faq-flex > .faq-group > .grid-item > .title > p{
    width: 100%;
    color: var(--primary);
    font-weight: 500;
    font-size: 20px;
}

#faq > .faq-flex > .faq-group > .grid-item > .title > .icon{
    padding: 7px;
    border-radius: 8px;
    background-color: var(--faq-icon-bg);
    transition: all 250ms ease-in-out;

}

#faq > .faq-flex > .faq-group > .grid-item:hover > .title > .icon,
#faq > .faq-flex > .faq-group > .grid-item.active > .title > .icon{
    background-color: var(--primary);
}

#faq > .faq-flex > .faq-group > .grid-item > .title > .icon > svg{
    min-width: 20px;
    min-height: 20px;
}

#faq > .faq-flex > .faq-group > .grid-item > .title > .icon > svg * {
    color: var(--primary);
    transition: all 250ms ease-in-out;
}

#faq > .faq-flex > .faq-group > .grid-item:hover > .title > .icon > svg *,
#faq > .faq-flex > .faq-group > .grid-item.active > .title > .icon > svg * {
    color: white;
}

#faq > .faq-flex > .faq-group > .grid-item > .explanation{
    color: var(--primary);
    font-weight: 300;
    font-size: 16px;
    line-height: 30px;
    display: none;
    text-align: justify;
}

#faq > .faq-flex > .faq-group > .grid-item.active > .explanation{
    display: block;
}

@media only screen and (max-width: 1308px){
    #faq > .faq-flex > .faq-group > .grid-item{
        height: 90px;
    }

    #faq > .faq-flex > .faq-group > .grid-item > .title > p{
        font-size: 18px;
    }
}

@media only screen and (max-width: 1024px){
    #faq > .faq-flex > .faq-group > .grid-item{
        gap: 35px;
    }
}


@media only screen and (max-width: 1023px){
    #faq > .faq-flex{
        flex-direction: column;
    }
    #faq > .faq-flex > .faq-group{
        width: 100%;
    }
}

@media only screen and (max-width: 781px){
    #faq > .faq-flex > .faq-group > .grid-item{
        padding: 15px 40px;
    }
}

@media only screen and (max-width: 768px){
    #faq > .title{
        font-size: 24px;
    }

    #faq > .faq-flex > .faq-group > .grid-item > .title > p{
        font-size: 18px;
    }

    #faq > .faq-flex > .faq-group > .grid-item > .explanation{
        font-size: 16px;
    }
}

@media only screen and (max-width: 767px){
    #faq > .faq-flex > .faq-group > .grid-item > .title > p{
        font-size: 16px;
    }
}

@media only screen and (max-width: 505px){
    #faq > .faq-flex > .faq-group > .grid-item{
        height: 80px;
    }
}

@media only screen and (max-width: 425px){
    #faq > .faq-flex > .faq-group > .grid-item{
        padding: 20px 30px;
    }
}