#innovationCarousel{
    width: 100%;
    height: 100%;
}

#innovationCarousel .swiper-container {
    position: relative;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
}
  
#innovationCarousel .swiper-slide {
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 300px;
    z-index: 0;
}

#innovationCarousel .card{
    width: 100%;
    height: 100%;
}

#innovationCarousel .card > img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

#innovationCarousel .card > .text-content{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding: 40px;
    gap: 20px;
}

#innovationCarousel .card > .text-content *{
    color: white;
    font-weight: 600;
}

#innovationCarousel .card > .text-content > .bullets{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#innovationCarousel .swiper-pagination-bullet{
    width: 33px;
    border-radius: 0;
    background: var(--label);
    height: 9px;
}

#innovationCarousel .swiper-pagination-bullet-active{
    background: var(--primary) !important;
}