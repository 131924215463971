#membersCarousel{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 25px;
}

#membersCarousel > .wrapper{
    width: 100%;
    height: 100%;
    max-width: 1024px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

#membersCarousel > .wrapper > .header{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

#membersCarousel > .wrapper > .header > .label{
    color: var(--label);
    font-size: 22px;
    font-weight: 300;
    text-align: center;
}

#membersCarousel > .wrapper > .header > .title{
    color: var(--text);
    font-size: 32px;
    font-weight: 600;
    text-align: center;
}

#membersCarousel > .wrapper > .carousel{
    width: 100%;
    height: 390px;
    background: var(--text);
    display: flex;
}

#membersCarousel > .wrapper > .carousel > .text-content,
#membersCarousel > .wrapper > .carousel > .carousel-swiper{
    width: 50%;
}

#membersCarousel > .wrapper > .carousel > .carousel-swiper > {
    user-select: none;
}

#membersCarousel > .wrapper > .carousel > .text-content{
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    gap: 10px;
    user-select: none;
}

#membersCarousel > .wrapper > .carousel > .text-content *{
    color: white;
}

#membersCarousel > .wrapper > .carousel > .text-content > .name{
    font-weight: 600;
    font-size: 22px;
}

#membersCarousel > .wrapper > .carousel > .text-content > .desc{
    font-weight: 300;
    font-size: 18px;
    overflow: auto;
    text-align: justify;
    padding-right: 10px;
}

#membersCarousel .card-image{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

#membersCarousel .swiper-slide{
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
}

#membersCarousel .swiper-container{
    width: 100%;
    height: 100%;
}

#membersCarousel .swiper-pagination-bullet{
    width: 33px;
    height: 9px;
    border-radius: 0;
    background: var(--label);
    opacity: 0.5;
    user-select: none;
}

#membersCarousel .swiper-pagination-bullet-active{
    width: 33px;
    height: 9px;
    border-radius: 0;
    background: var(--primary);
    opacity: 1;
}

@media only screen and (max-width: 1024px){
    #membersCarousel > .wrapper{
        max-width: 768px;
    }

    #membersCarousel > .wrapper > .header > .label,
    #membersCarousel > .wrapper > .carousel > .text-content > .name{
        font-size: 20px;
    }

    #membersCarousel > .wrapper > .header > .title{
        font-size: 28px;
    }

    #membersCarousel > .wrapper > .carousel > .text-content > .desc{
        font-size: 16px;
    }
}

@media only screen and (max-width: 768px){
    #membersCarousel > .wrapper > .carousel{
        flex-direction: column;
        max-width: 512px;
        height: 100%;
    }
    

    #membersCarousel > .wrapper > .carousel > .text-content, 
    #membersCarousel > .wrapper > .carousel > .carousel-swiper{
        width: 100%;
    }
}

@media only screen and (max-width: 526px){
    #membersCarousel > .wrapper > .carousel{
        max-width: 100%;
    }
}

@media only screen and (max-width: 425px){
    #landingPageContent > .wrapper > .line-details > .lines > .secondary{
        width: 250px !important;
    }

    #landingPageContent > .wrapper > .line-details > .lines > .dark{
        width: 150px !important;
    }
}