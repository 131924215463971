#portfolioShowcaseContent{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#portfolioShowcaseContent > .wrapper{
    width: 100%;
    height: 100%;
    max-width: 1536px;
    padding: 100px;
    gap: 50px;
    display: flex;
    align-items: flex-start;
}

#portfolioShowcaseContent > header{
    position: relative;
    width: 100%;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
}

#portfolioShowcaseContent > header > .background{
    position: absolute;
    width: 100%;
    height: 100%;
}

#portfolioShowcaseContent > header > .background::after{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: var(--hero-overlay-secondary);
    z-index: -1;
}

#portfolioShowcaseContent > header > .background > img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center 50%;
    z-index: -2;
}

#portfolioShowcaseContent > header > .text-content{
    position: relative;
    width: 100%;
    max-width: 1536px;
    padding: 20px 100px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 30px;
    z-index: 1;
}

#portfolioShowcaseContent > header > .text-content > .title{
    color: white;
    font-size: 42px;
    font-weight: bold;
    max-width: 630px;
}

#portfolioShowcaseContent > header > .text-content > .desc{
    color: white;
    font-size: 18px;
    font-weight: 300;
    max-width: 630px;
}

#portfolioShowcaseContent > header > .text-content > .action{
    height: 48px;
    width: 158px;
    color: white;
    font-weight: 600;
    background-color: var(--primary);
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#portfolioShowcaseContent > header > .text-content > .action:hover{
    background-color: white;
    color: var(--primary);
}

#portfolioShowcaseContent > .wrapper > .image{
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
}

#portfolioShowcaseContent > .wrapper > .image > img{
    width: 100%;
    height: 100%;
    max-height: 467px;
    object-fit: cover;
}

#portfolioShowcaseContent > .wrapper > .image > button{
    width: 100%;
    height: 48px;
    border: 1px solid transparent;
    border-radius: 4px;
    background-color: var(--accent-3);
    color: white;
    font-weight: 600;
    cursor: pointer;
    transition: all 250ms ease-in-out;
}

#portfolioShowcaseContent > .wrapper > .image > button:hover{
    background-color: white;
    color: var(--accent-3);
    border-color: var(--accent-3);
}

#portfolioShowcaseContent > .wrapper > .details{
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

#portfolioShowcaseContent > .wrapper > .details > .title{
    font-size: 22px;
    font-weight: 600;
}

#portfolioShowcaseContent > .wrapper > .details > .date{
    font-size: 14px;
    font-weight: 300;
    color: var(--label);
}

#portfolioShowcaseContent > .wrapper > .details > .detail-paragraph{
    font-weight: 300;
    line-height: 150%;
    font-size: 18px;
}

#portfolioShowcaseContent > .wrapper > .details > .features-grid{
    padding: 30px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}

#portfolioShowcaseContent > .wrapper > .details > .features-grid > .feature{
    display: flex;
    align-items: center;
    gap: 20px;
}

#portfolioShowcaseContent > .wrapper > .details > .features-grid > .feature > svg{
    min-width: 20px;
    min-height: 20px;
}

#portfolioShowcaseContent > .wrapper > .details > .features-grid > .feature > svg * {
    color: #1CF17E;
}

#portfolioShowcaseContent > .wrapper > .details > .features-grid > .feature > p{
    font-size: 18px;
    font-weight: 300;
}

#portfolioShowcaseContent > .wrapper > .details > .client-testimonials{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#portfolioShowcaseContent > .wrapper > .details > .client-testimonials > .label{
    font-size: 18px;
    font-weight: 400;
}

#portfolioShowcaseContent > .wrapper > .details > .client-testimonials > .testimonial{
    font-weight: 300;
    line-height: 150%;
    color: var(--label);
}

#portfolioShowcaseContent > .wrapper > .details > .client-testimonials > .client{
    font-weight: 300;
    color: var(--label);
    width: 100%;
    text-align: end;
}

@media only screen and (max-width: 1024px){
    #portfolioShowcaseContent > header > .text-content{
        padding: 20px 50px;
    }

    #portfolioShowcaseContent > .wrapper{
        padding: 50px;
    }

    #portfolioShowcaseContent > .wrapper > .image{
        position: sticky;
        top: 100px;
    }
}

@media only screen and (max-width: 768px){
    #portfolioShowcaseContent > header > .text-content > .title{
        font-size: 36px;
    }

    #portfolioShowcaseContent > header > .text-content > .desc,
    #portfolioShowcaseContent > .wrapper > .details > .detail-paragraph,
    #portfolioShowcaseContent > .wrapper > .details > .features-grid > .feature > p{
        font-size: 16px;
    }

    #portfolioShowcaseContent > header > .text-content > .action,
    #portfolioShowcaseContent > .wrapper > .image > button{
        font-size: 14px;
    }

    #portfolioShowcaseContent > .wrapper > .details > .features-grid{
        padding: 0;
    }
}

@media only screen and (max-width: 767px){
    #portfolioShowcaseContent > .wrapper{
        flex-direction: column;
    }
    #portfolioShowcaseContent > .wrapper > .image,
    #portfolioShowcaseContent > .wrapper > .details{
        width: 100%;
    }

    #portfolioShowcaseContent > .wrapper > .image{
        position: relative;
        top: 0;
    }
}

@media only screen and (max-width: 425px){
    #portfolioShowcaseContent > header > .text-content{
        padding: 20px;
    }

    #portfolioShowcaseContent > .wrapper{
        padding: 20px;
    }

    #portfolioShowcaseContent > .wrapper > .details > .features-grid{
        grid-template-columns: 1fr;
    }
}