#dealerTestimonyCarousel{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

#dealerTestimonyCarousel > .title{
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
}

#dealerTestimonyCarousel > .label{
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 300;
}

#dealerTestimonyCarousel .swiper-container{
    padding: 20px 0;
    width: 100%;
}

#dealerTestimonyCarousel .swiper-slide {
    background-color: var(--card-bg);
    border-radius: 15px;
    box-shadow: 0 0 20px -5px white;
    padding: 20px;
    max-height: 275px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
}

#dealerTestimonyCarousel .card *{
    color: var(--text) !important;
}

#dealerTestimonyCarousel .card{
    width: 100%;
    height: 100%;
    
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

#dealerTestimonyCarousel .card > .image{
    width: 20%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

#dealerTestimonyCarousel .card > .image > img{
    width: 62px;
    height: 62px;
    object-fit: cover;
    border-radius: 50%;
}

#dealerTestimonyCarousel .card > .review{
    width: 80%;
    height: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

#dealerTestimonyCarousel .card > .review > .client-details{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#dealerTestimonyCarousel .card > .review > .client-details > .name{
    font-size: 24px;
    font-weight: 600;
}

#dealerTestimonyCarousel .card > .review > .client-details > .role,
#dealerTestimonyCarousel .card > .review > .date{
    font-size: 14px;
    color: var(--label) !important;
}

#dealerTestimonyCarousel .card > .review > .review-text{
    font-size: 14px;
    font-weight: 300;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 7;
    overflow: hidden;
}

@media only screen and (max-width: 425px){
    #dealerTestimonyCarousel > .title,
    #dealerTestimonyCarousel .card > .review > .client-details > .name{
        font-size: 20px;
    }

    #dealerTestimonyCarousel > .label{
        font-size: 16px;
    }
}
