#productDialog{
    position: fixed;
    top: 0;
    left: 0;
    width: 100dvw;
    height: 100dvh;
    z-index: 999;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;  
    pointer-events: none;
    transition: opacity 0.2s ease-in-out;
    padding: 20px;
    
}

#productDialog.show{
    opacity: 1;
    pointer-events: all;
    transition: opacity 0.2s ease-in-out;
}

#productDialog::after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--hero-overlay-dark);
}



#productDialog > .container{
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 770px;
    max-width: 1270px;
    background-color: white;
    z-index: 1000;
    border-radius: 15px;
    box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.25);
    overflow: hidden;
}

#productDialog > .container > .logo{
    position: absolute;
    top: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
}

#productDialog > .container > .background{
    position: absolute;
    width: 50%;
    height: 100%;
    top: 0;
    right: 0;
    z-index: -1;
    opacity: 0.3;
}

#productDialog > .container > .background > img{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

#productDialog > .container > .background::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: inset 30px 0 20px rgba(255, 255, 255, 1);
    z-index: 1;
    pointer-events: none;
}


#productDialog > .container > .header{
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    height: 30%;
}

#productDialog > .container > .header::after{
    position: absolute;
    width: 60%;
    height: 100%;
    top: 0;
    left: 0;
    content: '';
    background-color: var(--primary);
    clip-path: polygon(0% 0%, 100% 0%, 100% 0, 80% 100%, 0% 100%);
    z-index: -1;
}

#productDialog > .container > .header > .title{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

#productDialog > .container > .header > .title > p {
    max-width: 350px;
}

#productDialog > .container > .header > .title > .label{
    font-weight: 400;
    font-size: 18px;
    color: white;
}

#productDialog > .container > .header > .title > .main{
    font-weight: bold;
    font-size: 40px;
    color: white;
}

#productDialog > .container > .header > .action{
    display: flex;
    height: 100%;
    align-items: center;
}

#productDialog > .container > .header > .action > svg{
    width: 40px;
    height: 40px;
    cursor: pointer;
}

#productDialog > .container > .content{
    width: 50%;
    height: 50%;
    padding: 10px 30px;
    display: flex;
    overflow-y: auto;
    flex-direction: column;
    gap: 20px;
    overflow: auto;
}

#productDialog > .container > .content::-webkit-scrollbar{
    width: 4px;
    height: 4px;
}

#productDialog > .container > .content::-webkit-scrollbar-track{
    background-color: var(--hero-overlay-light);
}

#productDialog > .container > .content::-webkit-scrollbar-thumb{
    background-color: var(--secondary);
    opacity: 0.2;
    border-radius: 20px;
}

#productDialog > .container > .content > .service-details{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

#productDialog > .container > .content > .package-image{
    width: 100%;
    height: auto;
    object-fit: contain;
}



#productDialog > .container > .content > .service-details > .included-package{
    width: 100%;
    max-width: 300px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
}

#productDialog > .container > .content > .service-details > .included-package > .label{
    width: 10%;
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    background-color: var(--secondary);
    color: white;
    font-weight: 500;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#productDialog > .container > .content > .service-details > .included-package > .details{
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#productDialog > .container > .content > .service-details > .included-package > .details > .title{
    color: var(--primary);
    font-weight: bold;
    font-size: 20px;
}

#productDialog > .container > .content > .service-details > .included-package > .details > .label{
    color: var(--primary);
    font-weight: 400;
}

#productDialog > .container > .content > .service-details > .included-package > .details ul{
    display: flex;
    flex-direction: column;
    padding-left: 20px;
}

#productDialog > .container > .content > .service-details > .included-package > .details ul > li{
    font-weight: 400;
    color: var(--primary);
}


#productDialog > .container > footer{
    display: flex;
    width: 100%;
    height: 20%;
    padding-left: 40px;
    align-items: center;
    justify-content: space-between;
}

#productDialog > .container > footer > a{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 50px;
    border-radius: 10px;
    border: 1px solid var(--primary);
    color: var(--primary);
    font-weight: 600;
    font-size: 14px;
    transition: all 250ms ease-in-out;
}

#productDialog > .container > footer > a:hover{
    background-color: var(--primary);
    color: white;
}

#productDialog > .container > footer > .certification{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color: var(--secondary-light);
    height: 100%;
    border-top-left-radius: 150px;
}

#productDialog > .container > footer > .certification > img{
    width: 80%;
    height: 100%;
    object-fit: contain;
}

@media only screen and (max-width: 768px){
    #productDialog > .container > .header::after{
        width: 100%;
        clip-path: none;
    }

    #productDialog > .container > .background{
        display: none;
    }

    #productDialog > .container > .header > .action > svg *{
        color: white;
    }

    #productDialog > .container > .header > .title > .label{
        font-size: 16px;
    }

    #productDialog > .container > .header > .title > .main{
        font-size: 32px;
    }

    #productDialog > .container > .header{
        height: 25%;
    }

    #productDialog > .container > .content{
        height: 55%;
        width: 100%;
    }
}

@media only screen and (max-width: 767px){
    #productDialog > .container > .content{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    #productDialog > .container > footer{
        background-color: var(--secondary-light);
        padding-left: 10px;
    }

    #productDialog > .container > .content > .service-images,
    #productDialog > .container > .content > .service-details,
    #productDialog > .container > footer > .certification,
    #productDialog > .container > footer > .certification > img{
        width: 100%;
    }

    #productDialog > .container > footer > .certification{
        border-radius: 0;
        padding: 10px;
    }

    #productDialog > .container > .content > .service-details{
        flex-direction: row;
        align-items: center;
    }
}

@media only screen and (max-width: 581px){
    #productDialog > .container > .content > .service-details{
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 581px){
    #productDialog > .container > .header > .title > .label{
        font-size: 14px;
    }

    #productDialog > .container > .header > .title > .main{
        font-size: 28px;
    }

    #productDialog > .container > .header,
    #productDialog > .container > .content{
        padding: 20px;
    }

    #productDialog > .container > .content{
        display: block;
    }

    #productDialog > .container > .content > .service-details{
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 374px){
    #productDialog > .container > footer > a{
        font-size: 12px;
    }
}